const openNavBtn = document.querySelector(".open-nav-btn");
const closeNavBtn = document.querySelector(".close-nav-btn");
const navContainer = document.querySelector(".nav-container");
const list = document.querySelectorAll(".link");
const navigate = () => {
  // navContainer.classList.toggle("opacity-0");
  navContainer.classList.toggle("hidden");
  document.body.classList.toggle("overflow-hidden");
};

[openNavBtn, closeNavBtn].forEach((btn) =>
  btn.addEventListener("click", () => {
    navigate();
  })
);

const useLink = (e) => {
  e.preventDefault();
  const id = e.target.href.split("#").at(1);
  if (!id) return;
  document.getElementById(id).scrollIntoView({ behavior: "smooth" });
};
list.forEach((list) => {
  list.addEventListener("click", (e) => {
    useLink(e);
    if (e.target.classList.contains("nav-link")) {
      // navContainer.classList.add("opacity-0");
      navContainer.classList.add("hidden");
      document.body.classList.remove("overflow-hidden");
    }
  });
});

window.onload = function () {
  // Reset the form fields when the page loads
  document.getElementById("form").reset();
};
